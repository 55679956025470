import compact from 'lodash/compact';

export const getJobLocations = job => {
  if (job?.locations?.length) {
    return job.locations;
  }

  if (job?.location) {
    return [job.location];
  }

  return [];
};

export const getVisibleJobLocations = (locations = []) =>
  locations.filter(jobLocation => !jobLocation.hidden && jobLocation.country);

export const formatLocation = ({city, country, display, region}) =>
  display || compact([city, region, country]).join(', ');
