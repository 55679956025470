import React, {useContext} from 'react';
import Job from 'candidate/providers/job';
import AccountLogo from 'candidate/shared/account/logo';
import Details from 'candidate/shared/jobDetails';
import styles from './styles.scss';

const JobDetails = ({job}) => {
  if (job) {
    return (
      <>
        {job.title ? <h1 data-ui="job-title">{job.title}</h1> : null}
        <Details job={job} klass={styles.headerDetails} />
      </>
    );
  }

  return (
    <>
      <h1 className={styles.shimmerTextSmall} data-ui="job-title" />
      <Details />
    </>
  );
};

const Header = ({children, withoutJob = false, ...rest}) => {
  const {job} = useContext(Job) || {};
  return (
    <header className={styles.wrapper} role="banner" {...rest}>
      <div className={styles.header}>
        <div className={styles.logo} data-ui="header-logo">
          <AccountLogo />
        </div>
        {!withoutJob && <JobDetails job={job} />}
        {children}
      </div>
    </header>
  );
};

export default Header;
