import React from 'react';
import PropTypes from 'prop-types';
import TextBody from 'shared/ui/atoms/text/body';

const Location = ({city, country, display, region}) =>
  display || (
    <>
      {!!city && <TextBody key={city}>{city}, </TextBody>}
      {!!region && (
        <TextBody key={region} secondary={!!city}>
          {region},{' '}
        </TextBody>
      )}
      {!!country && (
        <TextBody key={country} secondary={!!region}>
          {country}
        </TextBody>
      )}
    </>
  );

Location.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  display: PropTypes.string,
  region: PropTypes.string
};

export default Location;
