import React from 'react';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import TextBody from 'shared/ui/atoms/text/body';
import {Job} from 'candidate/shared/enums';
import {getJobLocations, getVisibleJobLocations} from 'candidate/shared/job/location';
import {getWorkplaceType} from 'candidate/shared/job/workplace';
import MultipleLocations from 'candidate/shared/multipleLocations';
import styles from './styles.scss';

export const Shimmer = () => (
  <p className={styles.details}>
    <span className={styles.shimmer} />
  </p>
);

const JobDetails = ({job, klass, ...rest}) => {
  if (!job) {
    return <Shimmer />;
  }

  const {code, department: dep = '', remote = false, type, workplace} = job;
  const jobType = type && Job.TYPES[type]();
  const jobLocations = getJobLocations(job);
  const visibleLocations = getVisibleJobLocations(jobLocations);
  const department = isArray(dep) ? dep.join(', ') : dep;
  const workplaceType = getWorkplaceType({remote, workplace});

  return (
    <div className={klass} {...rest}>
      <p className={styles.details}>
        <TextBody data-ui="job-workplace" strong>
          {workplaceType}
        </TextBody>
        {department ? <span data-ui="job-department">{department}</span> : null}
        {jobType ? <span data-ui="job-type">{jobType}</span> : null}
        {code ? <span data-ui="job-code">{code}</span> : null}
      </p>
      {visibleLocations.length ? (
        <MultipleLocations className={styles.locationsContainer} dataUi="job-location" locations={visibleLocations} />
      ) : null}
    </div>
  );
};

JobDetails.propTypes = {
  job: PropTypes.shape({
    code: PropTypes.string,
    department: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    remote: PropTypes.bool,
    type: PropTypes.string,
    workplace: PropTypes.string
  }),
  klass: PropTypes.string
};

export default JobDetails;
