import {WORKPLACES} from 'candidate/shared/enums/job';

export const getWorkplaceType = ({remote, workplace} = {}) => {
  if (!workplace && typeof remote === 'boolean') {
    return remote ? WORKPLACES.remote() : WORKPLACES.on_site();
  }

  if (WORKPLACES[workplace]) {
    return WORKPLACES[workplace]();
  }

  throw new Error(`Unknown workplace type: ${workplace}`);
};
